.cardJob {
  padding: 25px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.068);
  background-color: white;
  border-radius: 5px;
  gap: 20px;
  overflow-y: auto;
  width: 100%;
}

.cardJob_mainInfo {
  display: flex;
  align-items: center;
  gap: 26px;
}

.cardJob_info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.selectedJob {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.cardJob img {
  width: 50px;
  height: 50px;
}
.cardJob_title {
  font-size: 18px;
  font-weight: 600;
}
.cardJob_company {
  color: #00bc92;
  font-size: 16px;
  font-weight: 600;
}
.cardJob_description {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.cardJob_info button {
  color: #1b2831;
  font-size: 14px;
  font-weight: 400;
  text-decoration-line: underline;
  text-align: start;
  width: 80px;
}

.cardJob_date {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 12px;
  font-weight: 350;
  gap: 10px;
}

@media (min-width: 1024px) {
  .cardJob_date {
    justify-content: start;
    flex-direction: row;
  }
}

.cardJob_date p:first-child {
  color: var(--green-aquinas);
  font-weight: 600;
}
.cardJob_date p:last-child {
  color: #212529;
  font-weight: 400;
}
