.media-content {
  background: #f3f3f3;
  min-height: calc(100vh - 100px);
  margin-top: 100px;
}

@media (min-width: 1024px) {
  .media-content {
    padding-left: 300px;
  }
}

/* MODULO DE RESULTADOS */

.mediaResults {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  flex-direction: column;
  gap: 30px;
  padding: 30px 0;
}

@media (min-width: 700px) {
  .mediaResults {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1300px) {
  .mediaResults {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1600px) {
  .mediaResults {
    grid-template-columns: repeat(4, 1fr);
  }
}
