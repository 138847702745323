.metasearchOptionsContainer {
  display: none;
}

@media (min-width: 1024px) {
  .metasearchOptionsContainer {
    display: block;
  }
}

.metasearchBarContainer {
  width: 100%;
  display: flex;
}
.metasearchBar {
  color: var(--olib-blue);
  font-size: 14px;
  height: 60px;
  padding-left: 20px;
  border-radius: 5px 0 0 5px;
  border: none;
  background-color: #f4f5f6;
  width: 100%;
}

.metasearcherContainerWithoutBar {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
}

.metasearchButton {
  height: 60px;
  font-size: 25px;
  padding: 0 10px;
  border-radius: 0 5px 5px 0;
  background-color: #f4f5f6;
  display: flex;
  gap: 3px;
  align-items: center;
  color: rgba(34, 50, 99, 0.28);
  transition: 0.3s;
}

.metasearchButton:hover {
  background-color: var(--green-aquinas-hover);
}

@media (min-width: 1024px) {
  .metasearchButton {
    font-size: 14px;
    background-color: var(--green-aquinas);
    color: var(--white);
  }
}

.metasearchButton p {
  color: var(--white);
  display: none;
}

@media (min-width: 1024px) {
  .metasearchButton p {
    display: block;
  }
}

/* LOGIN */

.metasearcherLogin {
  position: relative;
}
