.cardNews {
  background-color: white;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  height: 500px;
}

.containerImageNews {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 300px;
  transition: transform 0.3s ease;
}

.containerImageNews img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease;
}

.containerImageNews:hover img {
  transform: translate(-50%, -50%) scale(1.1);
}

@media (min-width: 1650px) {
  .cardNews.big {
    grid-column: 2 / 4;
  }
}

.cardNewsContainer {
  padding: 30px 15px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.cardNewsContainer .cardNews__text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cardNews .info {
  display: flex;
  gap: 10px;
}

.cardNewsContainer {
  padding: 20px;
}

.cardNews__image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.cardNews__title {
  color: #252525;
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.cardNews__text {
  text-align: justify;
  font-size: 14px;
  color: #252525;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
