.dataBaseImg {
	height: 46px;
}

.dataBaseButton{
    padding: 5px 8px;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 10px;
    transition: 0.5s;
}
/* .dataBaseButton:hover{
    border: 1px solid rgba(0, 0, 0, 0.164);
} */