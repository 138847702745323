.artDetail {
	padding: 40px;
	display: flex;
	gap: 20px;
}

/* Seccion de imagenes */

.imageDetailContainer {
	width: 100%;
	max-width: 500px;
}

.imageDetailContainer a {
	/* width: 100%; */
	max-width: 500px;
}

.imageDetailContainer_big {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 150px;
}

@media (min-width: 400px) {
	.imageDetailContainer_big {
		height: 200px;
	}
}

@media (min-width: 480px) {
	.imageDetailContainer_big {
		height: 250px;
	}
}

@media (min-width: 550px) {
	.imageDetailContainer_big {
		height: 300px;
	}
}

.principalImage {
	/* max-height: 150px; */
	width: 100%;
	border-radius: 12px;
	object-fit: contain;
}

/* @media (min-width: 400px) {
	.principalImage {
		max-height: 200px;
	}
}

@media (min-width: 480px) {
	.principalImage {
		max-height: 250px;
	}
}

@media (min-width: 550px) {
	.principalImage {
		max-height: 300px;
	}
} */

.imageDetailContainer_small {
	margin-top: 10px;
	display: flex;
	justify-content: center;
	gap: 10px;
	flex-wrap: wrap;
}
.secondaryImages {
	border: 4px solid #fff;
	width: 50px;
	border-radius: 4px;
	padding: 3px;
}

@media (min-width: 480px) {
	.secondaryImages {
		width: 75px;
	}
}
@media (min-width: 680px) {
	.secondaryImages {
		width: 100px;
	}
}

.secondaryImagesSelected {
	border: 4px solid #242424d5;
}

/* Seccion de detalle */

.descriptionDetailContainer {
	display: flex;
	flex-direction: column;
	gap: 10px;
	font-size: 14px;
	width: 100%;
	text-align: start;
}

@media (min-width: 760px) {
	.descriptionDetailContainer {
		margin: 0 10px;
	}
}

.detail_title {
	font-size: 30px;
	color: #1b2831;
	font-weight: 600;
	text-align: start;
}

.showDescriptionButton {
	font-weight: 500;
	margin-bottom: 5px;
	color: #00bc92;
	text-decoration: underline;
}

.detail_description {
	text-align: justify;
}

/* Modal */

.modal-containerArt {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 999;
}

.modal-contenidoArt {
	background-color: #fff;
	padding: 15px 15px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	width: 100%;
	height: 80vh;
	text-align: end;
	margin-top: 100px;
	display: flex;
}

@media (min-width: 480px) {
	.modal-contenidoArt {
		padding: 20px 20px;
		/* gap: 10px; */
	}
}
@media (min-width: 1200px) {
	.modal-contenidoArt {
		width: 80%;
		gap: 10px;
		max-width: 1400px;
	}
}

.modal-contenidoArt-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 20px;
	overflow-y: auto;
	padding: 0 10px;
}

@media (min-width: 760px) {
	.modal-contenidoArt-content {
		gap: 10px;
		flex-direction: row;
		align-items: start;
		padding: 0;
	}
}
