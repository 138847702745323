.sectionFilter {
  display: none;
  position: fixed;
  z-index: 52;
}
@media (min-width: 1024px) {
  .sectionFilter {
    display: block;
  }
}
.sectionFilter.activeFilter {
  display: block;
}

/* BACKGROUND */

.filterBarBackground.activeFilter {
  width: calc(100% - 370px);
  height: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: rgba(20, 30, 37, 0.34);
  cursor: pointer;
}

@media (min-width: 1024px) {
  .filterBarBackground.activeFilter {
    display: none;
  }
}

/* FILTERS BAR */

.filtersBar {
  width: 370px;
  height: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: var(--white);
}

@media (min-width: 1024px) {
  .filtersBar {
    display: block;
    height: calc(100% - 100px);
  }
}

.filtersBarIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

@media (min-width: 1024px) {
  .filtersBarIcon {
    display: none;
  }
}

.filtersBarMain {
  background-color: var(--white);
  display: flex;
  padding-top: 31px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(27, 40, 49, 0.23);
  flex-direction: column;
}

.filtersBarMain button {
  text-align: end;
  font-size: 16px;
}

@media (min-width: 1024px) {
  .filtersBarMain button {
    display: none;
  }
}

.filtersBarMain div {
  display: flex;
  gap: 5px;
}

.filtersBarMain h2 {
  font-size: 16px;
  font-weight: 600;
}

.filtersBarSection {
  background-color: var(--white);
  border-bottom: 1px solid rgba(27, 40, 49, 0.23);
  display: flex;
  flex-direction: column;
}

.filtersBarSection__main {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.filtersBarSection__main label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  font-weight: 600;
}
.filtersBarSection__main label input {
  color: red;
}
.filtersBarSection__main h3 {
  font-size: 15px;
  font-weight: 600;
}
.filtersBarSection__main button {
  font-size: 13px;
  font-weight: 400;
  color: var(--blue-aquinas);
}

.filtersBarSection__main button .iconActive {
  transform: rotate(180deg);
}

.filtersBarSectionFilter__content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 20px;
}

.filtersBarSectionFilter__content button {
  padding: 6px;
  border-radius: 5px;
  background-color: var(--green-aquinas);
  color: var(--white);
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
}

.filtersBarSection__content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.filtersBarSection__content.fixed {
  max-height: 150px;
  overflow-y: auto;
  padding-bottom: 20px;
}

.filtersBarSection__content button {
  padding: 3px 6px;
  border-radius: 5px;
  border: 0.25px solid #1b28314b;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 5px;
  /* text-transform: lowercase; */
}

.filtersBarSection__content button.selected {
  background-color: var(--green-aquinas);
  color: var(--white);
}

.applyButton {
  margin: 30px 0;
  height: 50px;
  color: var(--white);
  background-color: var(--green-aquinas);
  border-radius: 5px;
  width: 100%;
}

.optionsIcon {
  font-size: 20px;
}
