.jobs {
  margin-top: 100px;
  width: 100%;
  height: calc(100vh - 100px);
  background-color: #f0f0f0;
}

@media (min-width: 1024px) {
  .jobs {
    margin-left: 300px;
    width: calc(100% - 300px);
  }
}

@media (min-width: 1300px) {
  .jobsContainer {
    display: flex;
    gap: 20px;
  }
}

.jobsSummary {
  padding: 30px 0;
}
.jobsSummary .filters {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
.categoryBarJob {
  background-color: #f0f0f0;
  border: none;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* Search Bar */
.searchBarJobs {
  width: 100%;
  margin: 16px auto 0;
}
@media (min-width: 760px) {
  .searchBarJobs {
    max-width: 570px;
  }
}

/* Container jobs results */
.jobsList {
  display: flex;
}

.jobsList_summary {
  border-bottom: 1px solid #00000013;
  padding: 20px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
}
.jobsList_summary p {
  font-size: 16px;
  font-weight: 600;
}

.jobsList_summary select {
  font-size: 600;
}

.scroll-container {
  width: 100%;
  height: calc(100vh - 184px);
  overflow-y: auto;
}

@media (min-width: 1300px) {
  .scroll-container {
    width: 410px;
  }
}
.jobsList_results {
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.jobsList_resultsFilters {
  gap: 15px;
  width: 100%;
  padding: 0 20px;
  height: calc(100vh - 100px);
}

@media (min-width: 1300px) {
  .jobsList_resultsFilters {
    width: 410px;
  }
}

.jobsList_resultsFilters .containerCard {
  margin: 10px 0;
}

.jobsList_results_loading {
  margin: 10px 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.showMoreJobs {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  color: #00bc92;
}

.noDataContainerJobs {
  min-height: calc(100vh - 100px);
  font-size: 28px;
  color: #25252588;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
}
.noDataContainerJobs p {
  font-size: 14px;
}

.deactivate {
  display: none;
}

@media (min-width: 1300px) {
  .deactivate {
    display: block;
  }
}
