.card-video {
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
}

.card-video .resource_content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.card-video .thumbnail_image {
  width: 100%;
  height: 250px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
}

.card-video .thumbnail_image__play-button {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-video .thumbnail_image__play-button .button-svg {
  width: 60px;
  z-index: 1;
}

.card-video .thumbnail_image .thumbnail {
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-video .thumbnail_time {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 12px;
  background: var(--light-grey);
  border-radius: 5px;
  bottom: 0.6rem;
  right: 0.6rem;
  font-size: 12px;
  font-weight: 400;
}

.card-video .resource_title {
  font-size: 20px;
  font-weight: 600;
  height: 55px;
  overflow: hidden;
  width: 100%;
}

.card-video .resource_description {
  font-weight: 400;
  overflow: hidden;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  height: 50px;
  text-align: justify;
}

.card-video .resource_date {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 0.5rem 0;
}

.videoModal {
  height: 30vh;
  width: 65vw;
}

@media (min-width: 760px) {
  .videoModal {
    height: 40vh;
  }
}

@media (min-width: 1000px) {
  .videoModal {
    height: 50vh;
  }
}

.ReactModal__Overlay {
  z-index: 999;
}
