:root {
  --navy-blue: #223263;
  --gold: #f8bf52;
  --grey: #252525;
  --light-grey: #d1d1d1;
  --velvet: #780303;
  --aqua: #0a6d7b;
  --green-aquinas: #00bc92;
  --green-aquinas-hover: #02a884;
  --blue-aquinas: #1b2831;
  --white: #ffffff;
  --olib-blue: #1b2831;
  --olib-grey: #d6d9dcce;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

pre {
  white-space: pre-wrap;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
p,
li,
ul,
a,
button {
  margin: 0;
  padding: 0;
  color: var(--olib-blue);
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  border: none;
  background-color: #ffffff00;
}

@keyframes sweep {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

div,
header,
section,
ul,
details[open] summary ~ * {
  animation: sweep 0.5s ease-in-out;
}
