.patents-content {
  background: #f3f3f3;
  min-height: calc(100vh - 100px);
  margin-top: 100px;
}

@media (min-width: 1024px) {
  .patents-content {
    padding-right: 370px;
    padding-left: 300px;
  }
}


/* MODULO DE RESULTADOS */

.patents-content .results {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 0;
}