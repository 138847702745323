.message-container {
	position: relative;
	margin-left: auto;
	cursor: pointer;
}

.message {
	width: 290px;
	position: absolute;
	left: -290px;
	background-color: white;
	padding: 5px;
	border-radius: 5px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.7);
	z-index: 3;
	transition: opacity 0.1s ease;
	list-style-type: circle;
}

.message li::before {
	content: '\2022';
	color: #00bc92;
	font-size: 17px;
	margin-right: 5px;
}
