.logiOptionsContainer {
  display: flex;
  gap: 10px;
}

.loginButton {
  height: 44px;
  border: 1px solid var(--green-aquinas);
  border-radius: 5px;
  padding: 0 10px;
  color: var(--green-aquinas);
  font-weight: 600;
  font-size: 15px;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

.loginButton:hover {
  color: var(--white);
  background-color: #00bc9381;
}

.loginButton.noLogin {
  cursor: auto;
}

.loginButton.signup{
  border: none;
}

.userLoggedContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.userLogged {
  display: flex;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  padding: 20px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: var(--white);
  font-size: 14px;
}

.closeSession {
  height: 50px;
  position: absolute;
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  top: 81px;
  right: 0px;
  width: 160px;
  padding: 0 20px;
  background-color: var(--white);
  border: 1px solid rgba(27, 40, 49, 0.23);
  border-radius: 0 0 5px 5px;
}
