.backgroungImageCard {
	height: 530px;
	position: relative;
	border-radius: 20px;
	z-index: 1;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

@media (min-width: 850px) {
	.backgroungImageCard{
		height: 260px;

	}
}

@media (min-width: 1024px) {
	.backgroungImageCardBig {
		grid-row: 1 / 3;
		grid-column: 2 / 3;
		height: 100%;
	}
}

.backgroungImageCard:hover .descriptionCardArt {
	display: block;
}

.backgroungImageCard:hover .titleCardArtDescription {
	z-index: 3;
	bottom: 160px;
	left: 30px;
	
	color: var(--Grey, #252525);
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	transition: bottom 0.3s ease;
	width: 75%;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	
}


.descriptionCardArt p {
	color: var(--Blue-Aquinas, #1b2831);

	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;

	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	transition: bottom 0.3s ease;
	margin-bottom: 5px;
}

.descriptionCardArt div {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 10px;
	text-decoration-line: underline;
	color: var(--Olib-blue, #1b2831);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;

	position: absolute;
	bottom: 10px;
}


.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 20px;
	z-index: 2;
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0) 0%,
		rgba(27, 40, 49, 0.7) 100%
	);
}

.titleCardArt {
	content: '';
	position: absolute;
	bottom: 20px;
	left: 20px;
	color: #ffffff;
	font-size: 16px;
	font-weight: 600;
	width: 80%;
	z-index: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	transition: bottom 0.3s ease;
}

.containerMediaIconsArt {
	background-color: white;
	padding: 5px;
	position: absolute;
	top: 10px;
	right: 10px;
	border-radius: 8px;
}

.descriptionCardArt {
	min-height: 170px;
	background-color: rgba(255, 255, 255, 0.856);
	padding: 40px 10px 5px;
	border-radius: 8px;
	content: '';
	position: absolute;
	bottom: 20px;
	z-index: 2;
	width: 90%;
	display: none;
	left: 15px;
}
