.media-resource-card {
  background: var(--white);
  width: 100%;
  padding: 20px 31px;
  border-radius: 5px;
  /* box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15); */
  display: flex;

  align-items: center;
  flex-direction: column;
  gap: 10px;
  max-width: 300px;
  margin: 0 auto;
}

.resource_content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.media-resource-card .thumbnail_image {
  /* height: 220px; */
  position: relative;
}
.media-resource-card .thumbnail_image__play-button {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.media-resource-card .thumbnail_image__play-button .button-svg {
  width: 40px;
}

.media-resource-card .thumbnail_image .thumbnail {
  width: 230px;
  background: cyan;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
}

.media-resource-card .thumbnail_timer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 12px;
  border-radius: 32px;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  border-radius: 32px;
  bottom: 0.6rem;
  left: 0.6rem;
}

.media-resource-card .resource_title {
  font-size: 16px;
  font-weight: 600;
  height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
}

.media-resource-card .resource_description {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.media-resource-card .resource_date {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 0.5rem 0;
}
