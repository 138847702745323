.loadingComponent {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotationLoading 1s linear infinite;
}
.loadingComponent.green {
  border-top: 3px solid #00bc92;
}
.loadingComponent.black {
  border-top: 3px solid var(--olib-blue);
}

@keyframes rotationLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
