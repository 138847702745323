/* ------- Container ------*/
.art {
  margin-top: 100px;
  padding-top: 20px;
  padding-bottom: 40px;
  min-height: calc(100vh - 100px);
  gap: 20px;
}

@media (min-width: 1024px) {
  .art {
    margin-left: 300px;
    margin-right: 370px;
  }
}

.filtersArtContainer {
  display: flex;
  justify-content: space-between;
}

/* Results section */

.containerResultsArt_query {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
}

.containerResultsArt {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  min-height: 820px;
}

@media (min-width: 850px) {
  .containerResultsArt {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .containerResultsArt {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 1200px) {
  .containerResultsArt {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1400px) {
  .containerResultsArt {
    grid-template-columns: repeat(3, 1fr);
  }
}
