.container-component__olib {
  width: 100%;
  max-width: 1600px;
  margin: auto;
  padding: 0 1rem;
}

@media (min-width: 768px) {
  .container-component__olib {
    padding: 0 1.5rem;
  }
}
