.headerTutoring {
	margin-top: 100px;
	min-height: 280px;
	background: linear-gradient(180deg, #1b283173 0%, #1b2831e6 50%),
		url(../../Images/NewsPage/header-background.webp);
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	gap: 24px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
	text-align: center;
	padding: 40px 0;
}
.headerTutoringTitle {
	width: 80%;
	margin: 0 auto;
	font-size: 48px;
	font-weight: 400;
}
@media (min-width: 760px) {
	.headerTutoringTitle {
		width: 100%;
	}
}
.headerTutoringText {
	width: 80%;
	margin: 0 auto;
	font-size: 24px;
	font-weight: 400;
}

@media (min-width: 760px) {
	.headerTutoringText {
		width: 100%;
	}
}

.tutoring {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f3f3f3;
}
.tutoringContainer {
	width: 100%;
	margin: 64px auto;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.sheduleTitle {
	font-size: 28px;
}
.scheduleByMonth ::marker {
	content: none;
}
.sheduleTitle ::marker {
	font-size: 28px;
	content: normal;
}
@media (min-width: 480px) {
	.sheduleTitle {
		display: flex;
		align-items: center;
		gap: 10px;
	}
}
.sheduleTitle__list {
	display: list-item;
}
.sheduleTitle p {
	font-size: 14px;
	font-size: 400;
	padding: 8px;
	max-width: 160px;
	background-color: #eeeeee;
	border-radius: 8px;
}
.scheduleByMonth {
	background-color: white;
	border-radius: 8px;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
	padding: 24px;
}
.scheduleContainer {
	margin-top: 24px;
	display: grid;
	gap: 10px;
}
@media (min-width: 600px) {
	.scheduleContainer {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}
@media (min-width: 1000px) {
	.scheduleContainer {
		grid-template-columns: 1fr 1fr 1fr;
	}
}
@media (min-width: 1400px) {
	.scheduleContainer {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}
