.library-content {
  background: #f3f3f3;
  min-height: calc(100vh - 100px);
}

@media (min-width: 1024px) {
  .library-content {
    margin-top: 100px;
    padding-right: 370px;
    padding-left: 300px;
  }
}

.library-content.noSimple {
  margin-top: 0;
  min-height: calc(100vh - 300px);
}

.metasearchAdvanceContainerArticles {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

@media (min-width: 1024px) {
  .metasearchAdvanceContainerArticles {
    display: none;
  }
}

.noDataContainer {
  margin-top: 100px;
  min-height: calc(100vh - 100px);
  font-size: 28px;
  color: #25252588;
  background: #f3f3f3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noDataContainer.noSimple {
  margin-top: 0;
  min-height: calc(100vh - 300px);
}

@media (min-width: 1024px) {
  .noDataContainer {
    margin-left: 300px;
  }
}

.noDataContainer p {
  font-size: 14px;
}

/* MODULO DE RESULTADOS */

.library-content .results {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 0;
}

.metasearchLibraryContainer__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
