.navbar {
  display: none;
}

@media (min-width: 680px) {
  .navbar {
    display: flex;
    gap: 20px;
  }
}

.navbar .whiteText {
  font-size: 15px;
  color: var(--white);
  transition: 0.3s;
}
.navbar .blackText {
  font-size: 15px;
  color: var(--olib-blue);
  transition: 0.3s;
}

.navbar li a:hover {
  color: var(--green-aquinas);
}
