.sheduleCard {
  background-color: #00bc92;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  color: white;
  font-size: 12px;
}

.dateContainer {
  display: flex;
  justify-content: space-between;
  align-items: safe;
  border-bottom: 1px solid rgba(255, 255, 255, 0.663);
}

.date {
  font-size: 32px;
}

.sheduleInformation {
  display: grid;
  font-size: 16px;
}

.infoContainer {
  margin-top: 8px;
  grid-template-columns: repeat(2, 1fr);
}

.instructor {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
