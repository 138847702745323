.searchPage {
  margin-top: 100px;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchPageContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.searchPageContainerLogo {
  width: 180px;
  margin-bottom: 20px;
}

.searchPageContainerBar {
  display: flex;
  width: 100%;
}

.searchPageModules {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.searchPageModules a {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 75px;
  text-align: center;
}

.searchPageModules img {
  width: 40px;
  background-color: rgba(49, 27, 27, 0.31);
  padding: 10px;
  border-radius: 5px;
  height: 40px;
}

.searchPageModules a:hover img {
  transition: 0.5s;
  background-color: var(--green-aquinas);
}
