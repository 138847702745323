/* Resources section */

.resourcesHome {
  background-color: #f9fafc;
  display: flex;
  align-items: center;
}
.resourcesHomeContainer {
  width: 100%;
  margin: 0 auto;
  padding: 40px 0;
}

@media (min-width: 760px) {
  .resourcesHomeContainer {
    width: 100%;
    padding: 100px 0;
  }
}

.resourcesTitle {
  font-weight: 400;
  font-size: 30px;
  color: #1b2831;
  text-align: center;
}

.resourcesHomeList {
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 25px;
  margin: 32px auto;
}

@media (min-width: 460px) {
  .resourcesHomeList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 780px) {
  .resourcesHomeList {
    grid-template-columns: repeat(3, 1fr);
  }
}
