.chatPosition {
  z-index: 999;
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 14px;
  display: block;
}

@media (min-width: 760px) {
  .chatPosition {
    bottom: 40px;
    right: 40px;
  }
}

.chatPosition select {
  padding: 12px 50px 12px 14px;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  border-bottom: 0.3px solid #1b2831c9;
  background: #eaeeed;
}
.chatPosition input {
  display: flex;
  padding: 13px 14px;
  justify-content: center;
  align-items: center;
  gap: 198px;
  border-radius: 5px;
  background: #eaeeed;
  border: none;
}
.chatPosition textarea {
  display: flex;
  height: 130px;
  padding: 13px;
  align-items: flex-start;
  border-radius: 5px;
  background: #eaeeed;
  max-height: 300px;
  min-height: 150px;
  border: none;
}

.chatPosition .loadingForm {
  width: 100%;
  text-align: center;
  height: 50px;
}

.chatPosition .loadingForm span {
  width: 50px;
  height: 50px;
}

.chatPosition .submitButton {
  display: flex;
  font-size: 15px;
  font-weight: 700;
  height: 50px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 29px;
  align-self: stretch;
  border-radius: 5px;
  background: var(--green-aquinas);
  color: white;
  transition: 0.3s;
}

.chatPosition .submitButton:hover {
  background: var(--green-aquinas-hover);
}

/* Deactivate Section */

.chatDeactivate {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.507);
  border-radius: 50px;
  background-color: white;
}

.chatDeactivate p {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
  visibility: hidden;
  width: 0px;
  height: 30px;
}

.chatDeactivate:hover p {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 10px;
}

.chatDeactivate svg {
  background-color: white;
  border-radius: 50%;
  font-size: 35px;
}

/* Activate Section */

.chatActivate {
  background: #fff;
  border-radius: 10px 10px 0px 10px;
  box-shadow: 0px 0px 2.5px 0px rgba(0, 0, 0, 0.25);
  display: grid;
  min-width: 250px;
  max-width: 300px;
  padding: 25px;
  justify-content: center;
  gap: 10px;
  max-height: 500px;
  overflow-y: auto;
}

@media (min-width: 780px) {
  .chatActivate {
    max-width: 350px;
  }
}

.chatActivate.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
}

.successIcon {
  font-size: 24px;
  color: var(--green-aquinas);
}

.errorIcon {
  font-size: 35px;
  color: red;
}

.success .principalTextSuccess {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  max-width: 300px;
}
.success .auxiliaryTextSuccess {
  text-align: center;
  font-size: 15px;
  font-weight: 400;
}

.chatActivate .closeModal {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  font-weight: 800;
}

.chatActivate .principalText {
  max-width: 220px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.chatActivate .auxiliaryText {
  max-width: 220px;
  font-size: 14px;
  font-weight: 400;
}

.chatActivate .contactInfo {
  display: flex;
  gap: 10px;
}

.chatActivate .contactInfo input {
  width: 100%;
}
