.containerCard {
  width: 100%;
  background: #fdfdfd;
  min-height: 100px;
  padding: 23px 23px 23px 16px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
}
.titleCard {
  text-align: start;
  color: #2749b9;
  font-size: 18px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.titleCard:hover {
  color: #1d3688;
}

.textCard {
  line-height: 20px;
  font-size: 14px;
  color: #252525;
  text-align: justify;
  min-height: 40px;
  overflow: hidden;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.textCardFull {
  height: 100%;
}

.authorsCard {
  color: #252525;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.category {
  text-align: center;
  padding: 5px 10px;
  background-color: #d6d9dc;
  border: none;
  border-radius: 5px;
  font-weight: 400;
  font-size: 12px;
  color: #212529;
  user-select: none;
}

.categoryType {
  text-transform: capitalize;
}

.infoCard {
  display: flex;
  gap: 8px;
  margin-top: 15px;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.buttonAbstract {
  width: 80px;
  text-align: start;
  border: none;
  background-color: #ffffff;
  color: #1b2831;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
}

.peerReviewed {
  color: #fff;
  background-color: #00bc92;
  user-select: none;
}
