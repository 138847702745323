.header {
  top: 0;
  width: 100%;
  position: absolute;
}

.headerContainer {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbarAndLoginContainer {
  display: flex;
  gap: 32px;
}

.headerContainer__logo {
  display: flex;
  gap: 10px;
}

.logoInstitution {
  height: 58px;
  display: none;
}

@media (min-width: 400px) {
  .logoInstitution {
    display: flex;
  }
}
