.cardSection {
  margin: 0 auto;
  width: 300px;
  overflow: hidden;
  border-radius: 5px;
}

.cardSectionStyle {
  width: 300px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease;
}

.cardSectionStyle:hover {
  transform: scale(1.1);
}

.CardSection__title {
  color: white;
  padding: 0 10px;
  font-size: 25px;
  font-weight: 700;
  width: 300px;
}

.iaCardHome {
  height: 100%;
  display: flex;
  align-items: center;
  transform: scale(1) !important;
  position: relative;
}

.cardSectionStyle .iaCardHome .stars {
  position: absolute;
  top: 20px;
  right: -10px;
  transition: 0.5s;
}

.cardSectionStyle:hover .iaCardHome .stars {
  rotate: 30grad;
}

.cardSectionStyle:hover .backgroundImage {
  transform: scale(1.1);
}