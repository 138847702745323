.footerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

/* DOWN SECTION */

.rightsSection {
  background-color: var(--olib-blue);
  min-height: 60px;
}
.rightsSectionContainer {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  gap: 20px;
}
.rightsSectionContainer p {
  color: var(--white);
  font-size: 12px;
  font-weight: 400;
}
.rightsSectionContainer div {
  display: flex;
  gap: 8px;
  align-items: center;
}

.rightsSectionContainer div img {
  width: 70px;
}

@media (min-width: 768px) {
  .rightsSectionContainer div img {
    width: 140px;
  }
}
