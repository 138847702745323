.jobDescriptionContainer {
  border-right: 1px solid #00000013;
  background-color: var(--white);
  border-radius: 5px;
}
@media (min-width: 1300px) {
  .jobDescriptionContainer {
    display: block;
    position: relative;
  }
}

.scroll-containerDetail {
  width: 100%;
  height: calc(100vh - 184px);
  overflow-y: auto;
  padding: 0 50px;
}

.jobDescriptionContainer__summary {
  padding: 30px 0px;
  margin: 0 auto;
  border-bottom: 0.5px solid #2525254d;
}
.jobDescriptionContainer_img_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

@media (min-width: 1300px) {
  .jobDescriptionContainer_img_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0;
  }
}

.jobDescriptionContainer__content {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.jobDescriptionContainer__content img {
  width: 50px;
  height: 50px;
}
.jobDescriptionContainer__company {
  color: #00bc92;
  font-size: 16px;
  font-weight: 600;
}
.viewJobButton {
  background-color: #00bc92;
  border-radius: 5px;
  color: white;
  width: 150px;
  height: 50px;
  font-size: 15px;
  font-weight: 600;
}
.applyJobsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-top: 0.5px solid #2525254d;
  padding-top: 20px;
  margin-top: 10px;
}
.loagingApplyJobs {
  text-align: center;
}
.loader {
  margin: 10px auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #00bc92;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.applyJobOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.applyJobOptions a {
  padding: 10px 20px;
  background-color: #00bc92;
  color: white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backButton {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 20px;
}

@media (min-width: 1300px) {
  .backButton {
    display: none;
  }
}

.locationIcon {
  color: #00bc92;
}

.jobDescriptionContainer__detail {
  margin: 20px auto;
}
.contentInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contentInfo div {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 2px;
}

.contentInfo h2 {
  color: #212529;
  font-size: 25px;
  font-weight: 600;
}

.jobDescriptionContainer__description.time {
  background-color: #f87052;
  border-radius: 5px;
  padding: 5px 10px;
  width: auto;
  color: var(--white);
  cursor: auto;
}
.jobDescriptionContainer__description.publication {
  background-color: var(--light-grey);
  border-radius: 5px;
  padding: 5px 10px;
  width: auto;
  /* color: var(--white); */
  cursor: auto;
}

.jobDescriptionContainer__descriptionJob {
  font-size: 20px;
  font-weight: 600;
}

.jobDescriptionContainer__detailTitle {
  margin: 20px 0 10px;
  font-size: 16px;
  font-weight: 600;
}
.experienceSection {
  border-bottom: 0.5px solid #2525254d;
  padding-bottom: 20px;
}
.experienceSection ul li {
  list-style-type: disc;
  margin-left: 20px;
}

.moreDetailsDescription {
  /* max-width: 800px; */
  padding-bottom: 20px;
  font-size: 16px;
  border-bottom: 0.5px solid #2525254d;
}

.summarySection {
  border-bottom: 0.5px solid #2525254d;
  padding-bottom: 20px;
}

.summarySection p {
  font-weight: 400;
  margin-top: 7px;
}

.summarySection p span {
  font-weight: 350;
}

.moreDetailsSection {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}

.moreDetailsSection div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.viewMoreDetailsButton {
  color: #00bc92;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchJobsDetail {
  display: none;
}

@media (min-width: 1300px) {
  .searchJobsDetail {
    background-color: var(--white);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 184px);
    flex-direction: column;
    gap: 10px;
  }
}

.searchJobsDetail img {
  width: 60px;
}
