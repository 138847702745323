
.metasearchOptions {
  font-size: 14px;
  display: flex;
  gap: 7px;
  padding: 0 10px;
  background-color: #f3f3f3;  ;
  height: 60px;
  align-items: center;
}

.metasearchOptionsButtons {
  display: flex;
  align-items: center;
  height: 62px;
}

.metasearchOptionsButtons button {
  padding: 0 15px;
  height: 40px;
  color: var(olib-blue);
}

.metasearchOptionsButtons .buttonOption {
  background-color: var(--white);
}

.metasearchOptionsButtons .buttonOption:first-child {
  border-radius: 5px 0 0 5px;
}
.metasearchOptionsButtons .buttonOption:last-child {
  border-radius: 0 5px 5px 0;
}
.metasearchOptionsButtons .active {
  background-color: var(--green-aquinas);
  color: var(--white);
}

.generateIAButton {
  width: 170px;
  background-color: #22326369;
  padding: 18px 0;
  color: var(--white);
  border-radius: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generateIAButton.active {
  background-color: #223263;
}
