.loaderBooks {
  text-align: center;
  margin: 10px auto;
  margin-left: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #00bc92;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.selectedColor {
  border: 3px solid rgb(255, 255, 255);
}

.selectedColorBorder {
  border: 2px solid black;
  border-radius: 50%;
}

.showArtPeriodsButton {
  padding: 10px 0;
  color: var(--green-aquinas);
}
