.news-base {
  margin-top: 100px;
  min-height: calc(100vh - 100px);
  font-size: 28px;
  background: #f3f3f3;
}

@media (min-width: 1024px) {
  .news-base {
    margin-left: 300px;
  }
}

@media (min-width: 1400px) {
  .news-base {
    margin-right: 370px;
  }
}

.news-query {
  margin-top: 100px;
  min-height: calc(100vh - 100px);
  font-size: 28px;
  background: #f3f3f3;
}

@media (min-width: 1024px) {
  .news-query {
    margin-left: 300px;
  }
}
