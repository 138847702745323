.metasearchBar {
    color: var(--olib-blue);
    font-size: 14px;
    height: 60px;
    padding-left: 20px;
    border-radius: 5px 0 0 5px;
    border: none;
    background-color: #f4f5f6;
    width: 100%;
    outline: none;
  }
  
  .metasearcherContainerWithoutBar {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 1600px;
    margin: 0 auto;
  }
  