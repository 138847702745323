.message-containerDelete {
  position: relative;
  cursor: pointer;
}

.messageDelete {
  max-width: 220px;
  position: absolute;
  left: -100px;
  background-color: white;
  padding: 14px 21px 18px 21px;
  border-radius: 8px 0px 8px 8px;
  z-index: 3;
  transition: opacity 0.1s ease;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.279);
  list-style-type: circle;
}

.messageDelete li::before {
  content: '\2022';
  color: #00bc92;
  font-size: 17px;
  margin-right: 5px;
}

.message-containerDelete p {
  color: #252525;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
