/* ACTIVE SECTION */

.activeSection {
  background-color: #00bc9326;
  border-radius: 5px;
}

.EBookSection {
  display: flex;
  background-color: rgba(29, 45, 57, 0.03);
  border-radius: 5px;
  position: relative;
}

.activeSection img {
  filter: none !important;
}

.activeSection p {
  color: var(--green-aquinas) !important;
  font-weight: 600;
}

.newSection {
  text-align: end;
  color: var(--green-aquinas);
  position: absolute;
  right: 10px;
}

.sectionIcon {
  width: 17px;
  filter: brightness(0) saturate(100%) invert(0);
}

.newSource {
  width: 20px;
}

.starsBar{
  position: absolute;
  top: 4px;
  filter: brightness(0) saturate(100%) invert(0);
  right: 50px;
  width: 15px;

}