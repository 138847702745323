/* METASEARCH HOME */

.metasearchHome {
  min-height: 550px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.78) 0%,
      rgba(0, 0, 0, 0.78) 100%
    ),
    url(../../Images/HomePage/SectionHeader_background.webp) lightgray 50% /
      cover no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--white);
  display: flex;
  align-items: center;
  padding: 30px 0;
}
.metasearchHomeContainer {
  max-width: 506px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.metasearchHome h1 {
  font-size: 40px;
  font-weight: 400;
  margin-bottom: 10px;
  color: var(--white);
}

@media (min-width: 600px) {
  .metasearchHome h1 {
    font-size: 50px;
  }
}
.metasearchHome p {
  font-size: 24px;
  font-weight: 400;
  font-size: 18px;
  margin: 10px 0;
  color: var(--white);
}

/* SECTIONS INSTITUTIONS HOME*/

.sectionInstitutions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 35px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.sectionInstitutions p {
  font-size: 24px;
  text-align: center;
}

/* Topics section */

.topicsHome {
  background: linear-gradient(180deg, #1d2d39 18.59%, #000 177.23%);
  padding: 100px 0;
}

.topicsHomeContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.topicsHomeContainer h2 {
  font-size: 30px;
  font-weight: 400;
  color: var(--white);
  text-align: center;
}

.topicsClassButtonContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
}

/* Dictionary section */

.dictionaryHome {
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.87) 0%,
      rgba(0, 0, 0, 0) 138.59%
    ),
    url(../../Images/HomePage/SectionDictionary_background.webp) lightgray 50% /
      cover no-repeat;
  padding: 40px 0;
}
.dictionaryHomeContainer {
  min-height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  color: var(--white);
}
.dictionaryHomeContainer h2 {
  max-width: 450px;
  font-weight: 400;
  font-size: 35px;
  color: var(--white);
}
.dictionaryHomeContainer p {
  max-width: 550px;
  font-size: 22px;
  color: var(--white);
}
.goToDictionaryButton {
  color: var(--green-aquinas);
  border: 1px solid var(--green-aquinas);
  padding: 10px 30px;
  border-radius: 5px;
  font-weight: 600;
  margin-top: 30px;
  transition: 0.3s;
}
.goToDictionaryButton:hover {
  color: var(--white);
  background-color: #00bc9381;
}

/* DATABASES HOME */

.databasesHome {
  display: flex;
  flex-direction: column;
  gap: 35px;
  padding: 65px 0;
}

.databasesHome h2 {
  text-align: center;
  font-weight: 400;
  margin: 10px 0 20px 0;
  font-size: 25px;
}

.databasesHomeLogoContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 30px;
  row-gap: 10px;
}

@media (min-width: 1000px) {
  .databasesHomeLogoContainer {
    gap: 57px;
  }
}

/* Tutoring section */

@media (min-width: 1000px) {
  .tutoringHome {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }
}
.tutoringImage {
  width: 100%;
}
@media (min-width: 1000px) {
  .tutoringImage {
    max-width: 550px;
  }
}
.tutoringHomeContainer {
  padding: 20px 0;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
@media (min-width: 780px) {
  .tutoringHomeContainer {
    width: 80%;
  }
}
.tutoringTitle {
  color: #1b2831;
  font-size: 48px;
  font-weight: 400;
}
.tutoringText {
  color: #252525;
  font-size: 22px;
}
.tutorigBlockContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
}
@media (min-width: 780px) {
  .tutorigBlockContainer {
    gap: 16px;
  }
}
.tutorigBlock {
  width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #00bc92;
  border-left: 5px solid #00bc92;
  padding: 0 16px;
}
.tutorigBlockNumber {
  font-size: 38px;
}
.tutorigBlockText {
  font-size: 18px;
}
.goToTutoring {
  text-decoration: underline;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  gap: 10px;
  cursor: pointer;
  border: none;
  color: #1b2831;
}

/* News Section */

.newsHome {
  background: linear-gradient(180deg, #1d2d39 18.59%, #000 177.23%), #f9fafc;
  min-height: 550px;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px 0;
  text-align: center;
}

.newsHomeContainer {
  width: 100%;
  margin: 0 auto;
}

.newsHomeContainerTitle {
  color: var(--white);
  font-size: 35px;
}
.newsHomeContainerText {
  font-size: 16px;
  max-width: 400px;
  margin: 10px auto 0;
  color: var(--white);
}

.carouselNewsInfoPage {
  max-width: 1300px;
  margin: 0 auto;
}
