.loaderBooks {
  text-align: center;
  margin: 10px auto;
  margin-left: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #00bc92;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.inputFilterContainer {
  background: #f4f5f6;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.inputFilter {
  width: 100%;
  border-radius: 5px;
  background: #f4f5f6;
  border: 1px #b5bbbe;
  padding: 12px;
}

.inputFilterButton {
  border: none !important;
  font-size: 20px !important;
}


.inputFilterButton svg {
  color: #aaaaaa;
}

.filtersBarSection__content{
  padding-bottom: 20px;
}

.filtersBarSection__content.language {
  display: flex;
  flex-direction: column;
}

.labelLanguage {
  display: flex;
  gap: 5px;
}

.errorMessageFilter{
  text-align: center;
  color: red
}